import { __decorate } from "tslib";
import _ from "lodash";
import jslinq from "jslinq";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
let Aste = class Aste extends Vue {
  constructor() {
    super(...arguments);
    this.sizes = [];
    this.activeTemple = {};
    this.metalTemplesNotification = false;
    this.baseAcetatePath = "";
    this.baseStandardPath = "";
    this.materiale = "PL";
    this.animateTemplesColors = false;
    this.animateTemplesSize = false;
    this.animateTemplesMaterial = false;
    this.internalChange = false;
  }

  mounted() {
    this.getColors();
  }

  get templesColor() {
    return this.$store.getters["configurator/templesColor"];
  }

  set templesColor(value) {
    this.$store.commit("configurator/setTemplesColor", value);
  }

  get templesSize() {
    if (this.currentSubline == 'IG04TH') {
      if (this.$store.state.configurator.lungAste == null) {
        this.templesSize = "143";
      }

      return this.$store.state.configurator.lungAste;
    }

    return this.$store.state.configurator.misuraAste;
  }

  set templesSize(value) {
    if (this.currentSubline == 'IG04TH') {
      this.$store.state.configurator.lungAste = value;
    } else {
      this.$store.commit("configurator/setTemplesSize", value);
    }
  }

  get templesFinishing() {
    return this.$store.state.configurator.finituraAste;
  }

  set templesFinishing(value) {
    this.$store.commit("configurator/setTemplesFinishing", value);
  }

  get templesMaterial() {
    return this.$store.state.configurator.templesMaterial;
  }

  set templesMaterial(value) {
    this.$store.commit("configurator/setTemplesMaterial", value);
  }

  get temples() {
    return this.$store.state.configurator.aste.allColors.filter(t => this.currentSubline == 'IG04TH' ? t.U_THE_SOTTOLINEA == 'IG04TH' : t.U_THE_SOTTOLINEA != 'IG04TH');
  }

  unsetTemplesMetalColor() {
    this.$store.commit("configurator/unsetTemplesMetalColor");
  }

  get currentSubline() {
    var _this$$store$state$co;

    return (_this$$store$state$co = this.$store.state.configurator.frontale) === null || _this$$store$state$co === void 0 ? void 0 : _this$$store$state$co.U_THE_SOTTOLINEA;
  }

  getColors() {
    return this.$store.dispatch("configurator/aste/getAllColors");
  }

  getTempleImageUrl(t) {
    // recuper colori dalla cartella frontali perchè  nella cartella aste sono ruotate
    return process.env.VUE_APP_COLORS_URL + t.U_THE_COLORE + ".jpg";
  }

  setActiveTemple(t) {
    this.activeTemple = t;
    this.templesMaterial = t.materiale && t.materiale != "" ? t.materiale : "PL";
    this.templesColor = t; // Setta il materiale corretto

    if (t.materiale == "MT") {
      // Se metallo, setta GUN o GOLD in base al colore attualmente selezionato
      this.coloreMetalloAste = t.U_THE_COLORE.endsWith("GUN") ? "GUN" : "GOLD";
    } else {
      // Se plastica, togli il metalColor se presente
      this.unsetTemplesMetalColor();
    }

    if (t.U_THE_LUNGASTA != this.templesSize && this.currentSubline != 'IG04TH') {
      this.templesSize = t.U_THE_LUNGASTA;
    }
  }

  isActive(t) {
    return this.activeTemple == t;
  }

  get sizeButtons() {
    return this.sizes.filter(size => {
      return this.sizesAvailables.indexOf(size) >= 0;
    });
  }

  get filteredTemples() {
    let result = this.temples;

    if (this.templesSize && this.currentSubline != 'IG04TH') {
      result = result.filter(t => {
        return t.U_THE_LUNGASTA == this.templesSize;
      });
    }
    /**
     * A differenza del configuratore OSIX
     * per cui viene utilizzato il valore .METALTEMPLATE
     * qui utilizzo .materiale per identificare
     * quali siano le aste in metallo
     * e quali quelle in plastica
     */


    if (this.materiale && this.currentSubline != 'IG04TH') {
      result = result.filter(t => {
        if (this.materiale == "PL") {
          return t.materiale == "PL";
        } else {
          return t.materiale == "MT";
        }
      });
    }
    /**
     * essendoci colori per ogni versione del materiale
     * vado a identificare i colori per le aste in metallo GUN/GOLD
     */


    if (this.materiale == "MT" && this.coloreMetalloAste && this.currentSubline != 'IG04TH') {
      result = result.filter(t => {
        if (t.U_THE_COLORE) {
          if (this.coloreMetalloAste == "GUN") {
            return t.U_THE_COLORE.search("GUN") != -1;
          } else {
            return t.U_THE_COLORE.search("GOLD") != -1;
          }
        }
      });
    }
    /**
     * essendoci colori per ogni versione del materiale
     * vado ad identificare i colori con dicitura M
     * per separare le finiture Matt/Shiny
     */


    if (this.finituraAste) {
      result = result.filter(t => {
        if (t.U_THE_COLORE) {
          if (this.finituraAste == "M") {
            // ulteriore controllo per il colore LM12
            if (t.U_THE_COLORE.charAt(0) == "M") {
              return t.U_THE_COLORE.charAt(0) == "M";
            } else {
              return t.U_THE_COLORE.search("LM") != -1;
            }
          } else {
            if (t.U_THE_COLORE.charAt(0) == "M") {
              return t.U_THE_COLORE.charAt(0) != "M";
            } else {
              return t.U_THE_COLORE.search("LM") == -1;
            }
          }
        }
      });
    }

    return result;
  }

  get misuraAste() {
    return this.$store.state.configurator.misuraAste;
  }

  get finituraAste() {
    return this.$store.state.configurator.finituraAste;
  }

  set finituraAste(f) {
    this.templesFinishing = f;
  }

  get coloreMetalloAste() {
    return this.$store.state.coloreMetalloAste;
  }

  set coloreMetalloAste(c) {
    // TODO: vedere come gestire questa cosa in maniera più generica su configurator-base-app
    // perché PRO...272 e PRO...280 non c'entrano con iGreen
    this.$store.commit("configurator/setTemplesMetalColor", c == "GUN" ? "PRO0000272" : "PRO0000280");
    this.$store.commit("setTemplesMetalColor", c);
  }

  get sizesAvailables() {
    // Per IG04TH non abbiamo un'asta codificata per ogni misura. La misura in quel caso è un altro parametro "custom"
    if (this.currentSubline == 'IG04TH') {
      return [125, 130, 135, 143];
    }

    let temples = jslinq(this.temples);
    return temples.where(t => this.materiale == t.materiale).orderBy(t => t.U_THE_LUNGASTA).select(t => t.U_THE_LUNGASTA).distinct().toList();
  }

  noTempleAvailable() {
    if (this.filteredTemples.length == 0) {
      return true;
    } else {
      return false;
    }
  }

  onMaterialeChanged(newVal) {
    if (newVal == "MT") {
      this.templesSize = "143";
      this.templesFinishing = "M";
    }
  }

  onColoreMetalloAsteChanged(newVal) {
    /**
      Quando cambio colore del metallo
      sostituisco la porzione di nome necessaria
      per selezionare il colore se presente
    */
    let at = this.templesColor;
    let ma = "";

    if (!_.isEmpty(at)) {
      if (newVal != "GUN") {
        ma = at.U_THE_COLORE.replace("GUN", "GOLD");
      } else {
        ma = at.U_THE_COLORE.replace("GOLD", "GUN");
      }

      let a = this.temples.filter(t => {
        return t.U_THE_LUNGASTA == at.U_THE_LUNGASTA && t.U_THE_COLORE == ma;
      });

      if (a.length > 0) {
        this.setActiveTemple(a[0]);
      } else {
        if (this.filteredTemples.length != 0) {
          this.setActiveTemple(this.filteredTemples[0]);
        }
      }
    }
  }

  onTemplesSizeChanged(newVal) {
    /*
          se ho un'asta attiva e cambia la misura
          sostituisco all'asta attiva una con
          stesso colore, stesso materiale e misura selezionata
        */
    if (this.currentSubline == 'IG04TH') return;
    let at = this.templesColor;

    if (!_.isEmpty(at)) {
      let a = this.temples.filter(t => {
        return t.U_THE_COLORE == at.U_THE_COLORE && t.METALTEMPLE == at.METALTEMPLE && t.U_THE_LUNGASTA == newVal;
      });

      if (a.length > 0) {
        this.setActiveTemple(a[0]);
      } else {
        if (this.filteredTemples.length != 0) {
          this.setActiveTemple(this.filteredTemples[0]);
        }
      }
    }
  }

  onFinituraAsteChanged(newVal) {
    let at = this.templesColor;
    let f = "";

    if (!_.isEmpty(at)) {
      if (newVal == "M") {
        if (at.U_THE_COLORE.charAt(0) == "0") {
          f = at.U_THE_COLORE.replace(at.U_THE_COLORE.charAt(0), "M");
        } else if (at.U_THE_COLORE.charAt(1) != "M" && at.U_THE_COLORE.charAt(0) == "L") {
          f = at.U_THE_COLORE.replace(at.U_THE_COLORE.charAt(0), "LM");
        } else {
          f = at.U_THE_COLORE;
        }
      } else {
        if (at.U_THE_COLORE.charAt(0) == "M") {
          f = at.U_THE_COLORE.replace(at.U_THE_COLORE.charAt(0), "0");
        } else if (at.U_THE_COLORE.charAt(0) == "L") {
          f = at.U_THE_COLORE.replace(at.U_THE_COLORE.charAt(1), "");
        } else {
          f = at.U_THE_COLORE;
        }
      }

      let a = this.temples.filter(t => {
        return t.U_THE_LUNGASTA == at.U_THE_LUNGASTA && t.U_THE_COLORE == f;
      });

      if (a.length > 0) {
        this.setActiveTemple(a[0]);
      } else {
        if (this.filteredTemples.length != 0) {
          this.setActiveTemple(this.filteredTemples[1]); // this.setActiveTemple('');
        }
      }
    }
  }

  onTemplesColorChanged(newVal, oldVal) {
    if (newVal && (!oldVal || newVal.U_THE_COLORE != oldVal.U_THE_COLORE)) {
      this.activeTemple = newVal; // @ts-ignore
      // eslint-disable-next-line no-undef

      ga("create", "UA-124639943-3", "auto", "asteglobal"); // @ts-ignore
      // eslint-disable-next-line no-undef

      ga("asteglobal.send", "event", "aste", "setAsta", newVal.ItemName);
    }
  }

};

__decorate([Prop({
  default: false
})], Aste.prototype, "darkTheme", void 0);

__decorate([Watch("materiale")], Aste.prototype, "onMaterialeChanged", null);

__decorate([Watch("coloreMetalloAste")], Aste.prototype, "onColoreMetalloAsteChanged", null);

__decorate([Watch("templesSize")], Aste.prototype, "onTemplesSizeChanged", null);

__decorate([Watch("finituraAste")], Aste.prototype, "onFinituraAsteChanged", null);

__decorate([Watch("templesColor")], Aste.prototype, "onTemplesColorChanged", null);

Aste = __decorate([Component({})], Aste);
export default Aste;