import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";
let Clip = class Clip extends Vue {
  constructor() {
    super(...arguments);
    this.clipToVisualize = 0;
    this.includeMagnets = false;
  }

  get user() {
    return this.$store.state.configurator.user.user;
  }

  get numberOfClips() {
    return this.$store.state.configurator.numberOfClips;
  }

  set numberOfClips(value) {
    if (value > 0) {
      this.includeMagnets = true;
    } else {
      this.includeMagnets = false;
    }

    this.$store.commit("configurator/setNumberOfClips", value);
  }

  get lentiSole() {
    let lenti = this.$store.getters["configurator/lenti/clipLenses"];
    return lenti;
  }

  get selectedClips() {
    return this.$store.state.configurator.lentiSoleClip;
  }

  get showClip() {
    return this.$store.state.configurator.showClip;
  }

  set showClip(show) {
    this.$store.state.configurator.showClip = show;
  }

  get frontale() {
    return this.$store.state.configurator.frontale;
  }

  get correspondingFrontaleWithClip() {
    return this.$store.getters["configurator/correspondingFrontalWithClip"];
  }

  get correspondingFrontaleWithoutClip() {
    return this.$store.getters["configurator/correspondingFrontalWithoutClip"];
  }

  get items() {
    // Get all items and sort them based on the number present after the "." character
    return this.$store.state.configurator.frontali.items.sort((a, b) => {
      // Sort by TYPE
      if (a.TYPE === 'TOP' && b.TYPE !== 'TOP') return -1;
      if (a.TYPE !== 'TOP' && b.TYPE === 'TOP') return 1;
      if (a.TYPE === 'NEW' && b.TYPE !== 'NEW') return -1;
      if (a.TYPE !== 'NEW' && b.TYPE === 'NEW') return 1; // If TYPE is the same, sort by ORDERING

      if (a.ORDERING && b.ORDERING) {
        return a.ORDERING - b.ORDERING;
      } // If ORDERING is null, sort by name


      const id1 = parseInt(a.U_THE_MODELLO.substring(a.U_THE_MODELLO.lastIndexOf(".")).replace(/\D/g, ""));
      const id2 = parseInt(b.U_THE_MODELLO.substring(b.U_THE_MODELLO.lastIndexOf(".")).replace(/\D/g, ""));
      if (id1 > id2) return -1;
      if (id1 < id2) return 1;
    });
  }

  get correspondingClip() {
    return this.$store.getters["configurator/correspondingClip"];
  }

  get clipOn() {
    return this.$store.state.configurator.clip != null;
  }

  set clipOn(co) {
    if (this.frontale) {
      this.$store.commit("configurator/setClip", co ? this.correspondingClip : null);
    }
  }

  setClipLensesModel(l) {
    const correspondingClip = this.selectedClips.findIndex(selected => selected.ItemCode == l.ItemCode);

    if (correspondingClip != -1) {
      // The user clicked on an already chosen clip -> remove it
      this.$store.commit("configurator/removeClipLensesModel", l);
      this.clipToVisualize = this.selectedClips.length;
    } else {
      if (this.selectedClips.length < this.numberOfClips) {
        this.$store.commit("configurator/addClipLensesModel", l);
        this.clipToVisualize = this.selectedClips.length;
        this.$store.commit("configurator/setLenteClipToVisualize", l);
      } else if (this.selectedClips.length > 0) {
        this.$store.commit("configurator/replaceClipLensesModel", {
          lens: l,
          index: this.selectedClips.length - 1
        });
        this.clipToVisualize = this.selectedClips.length;
        this.$store.commit("configurator/setLenteClipToVisualize", l);
      }
    }
  }

  changedIncludeMagnets(newV) {
    if (newV) {
      this.$store.commit("configurator/setFrontal", this.correspondingFrontaleWithClip);
    } else {
      this.$store.commit("configurator/setFrontal", this.correspondingFrontaleWithoutClip);
    }
  }

  changedNumberOfClips(newV) {
    if (newV > 0 && !this.clipOn) {
      if (this.correspondingFrontaleWithClip) {
        this.$store.commit("configurator/setFrontal", this.correspondingFrontaleWithClip);
      }

      this.clipOn = this.correspondingClip;
      this.showClip = false;
    } else if (newV <= 0 && this.clipOn) {
      if (this.correspondingFrontaleWithoutClip) {
        this.$store.commit("configurator/setFrontal", this.correspondingFrontaleWithoutClip);
      }

      this.$store.commit("configurator/setLenteClipToVisualize", null);
      this.$store.commit("configurator/setClipLensesModels", []);
      this.clipOn = null;
    }

    if (this.selectedClips.length > newV) {
      this.selectedClips.splice(newV);
    } // If clipToVisualize is greater than the new number of clips, reset it to 0


    if (this.clipToVisualize > newV) {
      this.clipToVisualize = this.selectedClips.length;
    }
  }

  changedClipToVisualize(newV) {
    if (!this.showClip && newV > 0 && this.numberOfClips > 0) {
      this.showClip = true;
    }

    if (newV == 0) {
      console.log("HIDE CLIP");
      this.showClip = false;
    } else if (newV <= this.selectedClips.length) {
      this.$store.commit("configurator/setLenteClipToVisualize", this.selectedClips[newV - 1]);
    } else {
      this.$store.commit("configurator/setLenteClipToVisualize", null);
    }
  }

};

__decorate([Prop({
  default: false
})], Clip.prototype, "darkTheme", void 0);

__decorate([Watch("includeMagnets")], Clip.prototype, "changedIncludeMagnets", null);

__decorate([Watch("numberOfClips")], Clip.prototype, "changedNumberOfClips", null);

__decorate([Watch("clipToVisualize")], Clip.prototype, "changedClipToVisualize", null);

Clip = __decorate([Component({
  filters: {
    capitalize(s) {
      return _.capitalize(s);
    }

  }
})], Clip);
export default Clip;