import "core-js/modules/es.string.replace-all.js";
import { __decorate } from "tslib";
/* eslint-disable no-undef */

import { Component, Vue, Prop, Watch } from "vue-property-decorator"; //import '../plugins/vuetify' //forse non necessario su ogni componente

import _ from "lodash";
import hideMobileKeyboardOnReturn from "../plugins/utils";
let Frontali = class Frontali extends Vue {
  constructor() {
    super(...arguments);
    this.element = {
      title: "Forma",
      subtitle: "Scegli la forma frontale"
    };
    this.open = false;
    this.query = "";
    this.selectedSize = null;
    this.animateSize = false;
    this.noSizeSelectedDialog = false;
    this.previousSize = "";
    this.showSunModelNotAvailable = false;
    this.toggleThinModels = false;
  }

  mounted() {// We removed the next line since there were problems when loading a custom configuration with sun lenses from qrcode
    //   this.selectedFrontType = "V"; // By default, set the front type to "V"
  }

  get selectedFrontType() {
    return this.$store.state.configurator.glassType;
  }

  set selectedFrontType(val) {
    this.$store.commit("configurator/setLensesType", val, {
      root: true
    }); // Check whether the corresponding version in the new type is actually available
    // If not, reset the frontal model to null

    if (val == "S" || val == "V") {
      if (this.items.filter(i => {
        var _this$frontale, _this$frontale$U_THE_;

        return i.U_THE_CUSMODEL == "C" && i.U_TIPO_VSP == val && ((_this$frontale = this.frontale) === null || _this$frontale === void 0 ? void 0 : (_this$frontale$U_THE_ = _this$frontale.U_THE_MODELLO) === null || _this$frontale$U_THE_ === void 0 ? void 0 : _this$frontale$U_THE_.includes(i.U_THE_MODELLO.replaceAll("SC", "").replaceAll("C", "")));
      }).length <= 0) {
        this.$store.commit("configurator/setFrontal", null);
      }
    } else if (val == "C") {
      if (this.items.filter(i => {
        var _this$frontale2, _this$frontale2$U_THE;

        return i.U_THE_CUSMODEL == "CPL" && ((_this$frontale2 = this.frontale) === null || _this$frontale2 === void 0 ? void 0 : (_this$frontale2$U_THE = _this$frontale2.U_THE_MODELLO) === null || _this$frontale2$U_THE === void 0 ? void 0 : _this$frontale2$U_THE.includes(i.U_THE_MODELLO.replaceAll("CPL", "")));
      }).length <= 0) {
        this.$store.commit("configurator/setFrontal", null);
      }
    }

    this.$store.commit("setLenteDx", null);
    this.$store.commit("setLenteSx", null);
    this.$store.commit("resetLentiVista");
    this.$store.commit("configurator/lenti/resetCfgLenteVistaDx");
    this.$store.commit("configurator/lenti/resetCfgLenteVistaSx");
  }

  get thinClipOnEnabled() {
    var _this$$store$state$co;

    return (_this$$store$state$co = this.$store.state.configurator.user) !== null && _this$$store$state$co !== void 0 && _this$$store$state$co.extraAccess ? this.$store.state.configurator.user.extraAccess.includes("IGREEN-THIN-CLIP") : false;
  }

  get calibro() {
    const parsed = parseInt(this.$store.state.configurator.calibro);
    return isNaN(parsed) ? 0 : parsed;
  }

  get naso() {
    const parsed = parseInt(this.$store.state.configurator.naso);
    return isNaN(parsed) ? 0 : parsed;
  }

  get gtagId() {
    return this.$store.state.gtagId;
  }

  get frontaliniMeniscati() {
    return this.$store.state.frontaliniMeniscati;
  }

  get frontale() {
    return this.$store.state.configurator.frontale;
  }

  get user() {
    return this.$store.state.configurator.user.user;
  }

  get sizes() {
    return this.$store.state.configurator.frontali.itemsSizes;
  }

  get correspondingClip() {
    return this.$store.getters["configurator/correspondingClip"];
  }

  get clipOn() {
    return this.$store.state.configurator.clip != null;
  }

  set clipOn(co) {
    if (this.frontale) this.$store.commit("configurator/setClip", co ? this.correspondingClip : null);
  }

  get items() {
    // Get all items and sort them based on the number present after the "." character
    return this.$store.state.configurator.frontali.items.sort((a, b) => {
      // Sort by TYPE
      if (a.TYPE === 'TOP' && b.TYPE !== 'TOP') return -1;
      if (a.TYPE !== 'TOP' && b.TYPE === 'TOP') return 1;
      if (a.TYPE === 'NEW' && b.TYPE !== 'NEW') return -1;
      if (a.TYPE !== 'NEW' && b.TYPE === 'NEW') return 1; // If TYPE is the same, sort by ORDERING

      if (a.ORDERING && b.ORDERING) {
        return a.ORDERING - b.ORDERING;
      } // If ORDERING is null, sort by name


      const id1 = parseInt(a.U_THE_MODELLO.substring(a.U_THE_MODELLO.lastIndexOf(".")).replace(/\D/g, ""));
      const id2 = parseInt(b.U_THE_MODELLO.substring(b.U_THE_MODELLO.lastIndexOf(".")).replace(/\D/g, ""));
      if (id1 > id2) return -1;
      if (id1 < id2) return 1;
    });
  }

  get asianFitting() {
    return this.$store.state.configurator.asianFitting;
  }

  set asianFitting(af) {
    this.$store.commit("configurator/setAsianFitting", af);
  }

  get elementTitle() {
    return _.upperCase(this.element.title);
  }

  get filteredItems() {
    var query = this.query;
    let fData = this.items.filter(i => {
      if (this.selectedFrontType == "V") {
        return i.U_THE_CUSMODEL == "C" && i.U_TIPO_VSP == "V";
      } else {
        return i.U_THE_CUSMODEL == "C" && i.U_TIPO_VSP == "S";
      }
    });

    if (!this.thinClipOnEnabled) {
      fData = fData.filter(i => {
        return i.U_THE_SOTTOLINEA != 'IG04TH';
      });
    }

    if (this.toggleThinModels && this.thinClipOnEnabled) {
      fData = fData.filter(i => {
        return i.U_THE_SOTTOLINEA == 'IG04TH';
      });
    }

    if (this.query && this.query != "" && fData) {
      return fData.filter(function (item) {
        return _.includes(item.U_THE_MODELLO, query);
      });
    } else {
      return fData;
    }
  }

  get itemSizes() {
    let front = this.frontale;

    if (front && this.sizes.length > 0) {
      return this.sizes.filter(function (s) {
        return s.U_THE_MODELLO == front.U_THE_MODELLO;
      });
    }

    return [];
  }

  get finitura() {
    return this.$store.state.finitura;
  }

  set finitura(f) {
    this.$store.commit("configurator", "setFinishing", f);
  }

  get asianFittingText() {
    return this.asianFitting ? "YES " + this.$t("message.Asian_fitting") : "NO " + this.$t("message.Asian_fitting");
  }

  selectSizeFromSizes() {
    const s = this.itemSizes.filter(is => {
      var _this$frontale3;

      return is.ItemCode == ((_this$frontale3 = this.frontale) === null || _this$frontale3 === void 0 ? void 0 : _this$frontale3.ItemCode) && parseInt(is.size_eye) == this.calibro && parseInt(is.size_bridge) == this.naso;
    });

    if (s.length > 0) {
      this.selectedSize = s[0].id;
    }
  }

  setFrontal(value) {
    var _this$frontale4, _this$frontale5, _this$frontale6;

    if (((_this$frontale4 = this.frontale) === null || _this$frontale4 === void 0 ? void 0 : _this$frontale4.U_THE_SOTTOLINEA) != (value === null || value === void 0 ? void 0 : value.U_THE_SOTTOLINEA) && (((_this$frontale5 = this.frontale) === null || _this$frontale5 === void 0 ? void 0 : _this$frontale5.U_THE_SOTTOLINEA) == 'IG04TH' || (value === null || value === void 0 ? void 0 : value.U_THE_SOTTOLINEA) == 'IG04TH')) {
      // Reset materiale
      this.$store.commit("configurator/setTemplesMaterial", "PL"); // Reset colors

      this.$store.dispatch("configurator/frontali/setColor", "EP0001");
      this.$store.dispatch("configurator/aste/setColor", (value === null || value === void 0 ? void 0 : value.U_THE_SOTTOLINEA) == "IG04TH" ? "ASP0000898" : "ASP0000774"); // Reset finishing to Shiny

      this.$store.commit("configurator/setFinishing", "S");
      this.$store.commit("configurator/setTemplesFinishing", "S");

      if ((value === null || value === void 0 ? void 0 : value.U_THE_SOTTOLINEA) != 'IG04TH') {
        // Reset clip
        this.$store.commit("configurator/resetClip");
      }
    }

    this.$store.commit("configurator/setFrontal", value);

    if (((_this$frontale6 = this.frontale) === null || _this$frontale6 === void 0 ? void 0 : _this$frontale6.U_THE_SOTTOLINEA) == 'IG04TH' && (value === null || value === void 0 ? void 0 : value.U_THE_SOTTOLINEA) == 'IG04TH' && this.clipOn) {
      // Update the clip 3D object if the clip was enabled by the user
      this.clipOn = this.correspondingClip;
    }
  }

  setSize(value) {
    this.$store.commit("configurator/setSize", value);
  }

  clearSize() {
    this.$store.commit("configurator/setSize", {
      size_eye: "",
      size_bridge: ""
    });
  }

  setAsianFitting(value) {
    this.$store.commit("configurator/setAsianFitting", value);
  }

  setSunLensesModel(value) {
    this.$store.commit("configurator/setSunLensesModel", value);
  }

  setFrontaliniMeniscati(d) {
    this.$store.commit("setFrontaliniMeniscati", d);
  }

  hideMobileKeyboardOnReturn(e) {
    hideMobileKeyboardOnReturn(e);
  }

  setFrame(item) {
    this.setFrontal(item);

    if (this.selectedSize) {
      this.selectedSize = null;
      this.clearSize();
    }
  }

  isItemActive(item) {
    var _this$frontale7;

    return ((_this$frontale7 = this.frontale) === null || _this$frontale7 === void 0 ? void 0 : _this$frontale7.ItemCode) == (item === null || item === void 0 ? void 0 : item.ItemCode);
  }

  clearLenses() {
    this.$store.commit("setLenteSx", null);
    this.$store.commit("setLenteDx", null);
  }

  closeDialogErroreLenti() {
    this.$store.commit("openCloseDialogErroreLenti", false);
  }

  resetPreviousLenses() {
    this.$store.commit("configurator/setOftLensesDxModel", this.$store.state.lenteDx);
    this.$store.commit("configurator/setOftLensesSxModel", this.$store.state.lenteSx);
  }

  onSelectedFrontTypeChanged(newVal) {
    var _this$frontale8;

    this.setSunLensesModel(null);

    if (((_this$frontale8 = this.frontale) === null || _this$frontale8 === void 0 ? void 0 : _this$frontale8.U_THE_CUSMODEL) == 'CPL' && newVal == 'V') {
      return;
    }

    const m = this.items.filter(i => {
      var _this$frontale9, _this$frontale9$U_THE, _this$frontale9$U_THE2, _this$frontale9$U_THE3;

      return i.U_THE_MODELLO.replace("CPL", "").replace("SC", "").replace("C", "") == ((_this$frontale9 = this.frontale) === null || _this$frontale9 === void 0 ? void 0 : (_this$frontale9$U_THE = _this$frontale9.U_THE_MODELLO) === null || _this$frontale9$U_THE === void 0 ? void 0 : (_this$frontale9$U_THE2 = _this$frontale9$U_THE.replace("CPL", "")) === null || _this$frontale9$U_THE2 === void 0 ? void 0 : (_this$frontale9$U_THE3 = _this$frontale9$U_THE2.replace("SC", "")) === null || _this$frontale9$U_THE3 === void 0 ? void 0 : _this$frontale9$U_THE3.replace("C", "")) && i.U_THE_CUSMODEL != "CPL" && i.U_TIPO_VSP == newVal;
    });

    if (m.length > 0) {
      this.setFrontal(m[0]);
    }

    if (this.calibro && this.naso) {
      this.selectSizeFromSizes();
    }
  }

  onSelectedSizeChanged(newVal) {
    let s = this.itemSizes.filter(s => {
      return s.id == newVal;
    });

    if (s.length > 0) {
      const foundSize = this.sizes.find(el => {
        var _this$frontale10;

        return el.U_THE_MODELLO == ((_this$frontale10 = this.frontale) === null || _this$frontale10 === void 0 ? void 0 : _this$frontale10.U_THE_MODELLO) && Number(el.size_eye) == this.calibro && Number(el.size_bridge) == this.naso;
      });
      this.previousSize = foundSize == undefined ? null : foundSize.id;
      this.setSize(s[0]);
      this.noSizeSelectedDialog = false;
    } else {
      this.clearSize();
    }
  }

  onRevealSettingChanged(newVal) {
    //add animation class to element
    let self = this;

    if (newVal == "frontSize") {
      this.animateSize = true;
      setTimeout(function () {
        self.animateSize = false;
        self.$emit("reset-setting-item");
      }, 2000);
    }

    if (newVal == "frontSize") {
      this.animateSize = true;
      setTimeout(function () {
        self.animateSize = false;
        self.$emit("reset-setting-item");
      }, 2000);
    }
  }

  onStepperChangeChanged(newVal) {
    if (newVal[0] == 1 && this.calibro == 0) {
      //change from step 1
      this.noSizeSelectedDialog = true;
    }
  }

  onFrontaleChanged(newVal) {
    // @ts-ignore
    ga("create", this.gtagId, "auto", "frontaleglobal"); // @ts-ignore

    ga("frontaleglobal.send", "event", "frontale", "setForma", newVal === null || newVal === void 0 ? void 0 : newVal.U_THE_MODELLO);
  }

  onCalibroChanged() {
    // // @ts-ignore
    // ga("create", this.gtagId, "auto", "frontaleglobal");
    // // @ts-ignore
    // ga(
    //   "frontaleglobal.send",
    //   "event",
    //   "frontale",
    //   "setMisure",
    //   this.frontale.U_THE_MODELLO + " - " + newVal + "/" + this.naso
    // );
    if (this.naso) {
      this.selectSizeFromSizes();
    }
  }

  onNasoChanged() {
    if (this.calibro) {
      this.selectSizeFromSizes();
    }
  }

  onItemSizesChanged() {
    if (this.calibro && this.naso) {
      this.selectSizeFromSizes();
    }
  }

  onUserChanged() {
    // @ts-ignore
    this.$rest.getFrontaliniMeniscati().then(response => {
      let d = response.data;
      d = Object.values(d);
      this.setFrontaliniMeniscati(d);
    });
  }

};

__decorate([Prop({
  default: false
})], Frontali.prototype, "isOpen", void 0);

__decorate([Prop({
  default: false
})], Frontali.prototype, "revealSetting", void 0);

__decorate([Prop({
  default: false
})], Frontali.prototype, "darkTheme", void 0);

__decorate([Prop()], Frontali.prototype, "stepperChange", void 0);

__decorate([Watch("selectedFrontType")], Frontali.prototype, "onSelectedFrontTypeChanged", null);

__decorate([Watch("selectedSize")], Frontali.prototype, "onSelectedSizeChanged", null);

__decorate([Watch("revealSetting")], Frontali.prototype, "onRevealSettingChanged", null);

__decorate([Watch("stepperChange")], Frontali.prototype, "onStepperChangeChanged", null);

__decorate([Watch("frontale")], Frontali.prototype, "onFrontaleChanged", null);

__decorate([Watch("calibro")], Frontali.prototype, "onCalibroChanged", null);

__decorate([Watch("naso")], Frontali.prototype, "onNasoChanged", null);

__decorate([Watch("itemSizes")], Frontali.prototype, "onItemSizesChanged", null);

__decorate([Watch("user")], Frontali.prototype, "onUserChanged", null);

Frontali = __decorate([Component({
  filters: {
    capitalize: function (value) {
      return _.capitalize(value);
    }
  }
})], Frontali);
export default Frontali;