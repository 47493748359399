import { render, staticRenderFns } from "./UserCart.vue?vue&type=template&id=205b2e2a&scoped=true&lang=html"
import script from "./UserCart.vue?vue&type=script&lang=ts"
export * from "./UserCart.vue?vue&type=script&lang=ts"
import style0 from "./UserCart.vue?vue&type=style&index=0&id=205b2e2a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205b2e2a",
  null
  
)

export default component.exports