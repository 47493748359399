import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";
import itemCard from "./ItemCard.vue";
let UserCart = class UserCart extends Vue {
  constructor() {
    super(...arguments);
    this.orderNoteDialog = false;
    this.orderNote = "";
    this.checkoutStatusDialog = false;
    this.itemAddedToCart = false;
    this.cartUpdated = false;
    this.cartUpdatedNotify = "";
    this.showPrice = false;
    this.showConfirmOrder = false;
  }

  deleteCartRow(id) {
    this.$store.dispatch("configurator/cart/deleteCartRow", id);
  }

  emptyCart() {
    this.$store.dispatch("configurator/cart/emptyCart");
  }

  getModelPreviewImage(modello) {
    return process.env.VUE_APP_MODEL_URL + modello + ".png";
  }

  checkout() {
    let note = this.orderNote;
    this.orderNoteDialog = false;
    this.checkoutStatusDialog = true;
    this.$store.dispatch("configurator/cart/checkout", note);
  }

  openCart() {
    this.$emit("openCartDialog");
  }

  colorImageUrl(colore) {
    if (colore.Category != "MULTISTRATO") {
      return colore.imageUrl;
    } else {
      return colore.backImageUrl;
    }
  }

  get cartTotal() {
    return this.cartRows ? this.cartRows.length : 0;
  }

  get cartRows() {
    return this.$store.state.configurator.cart.cartRows;
  }

  get checkoutSuccess() {
    return this.$store.state.configurator.cart.checkoutSuccess;
  }

  get checkingOut() {
    return this.$store.state.configurator.cart.checkingOut;
  }

  get colore() {
    return this.$store.state.configurator.colore;
  }

  get frontali() {
    return this.$store.state.configurator.frontali.items;
  }

  get user() {
    return this.$store.state.configurator.user.user;
  }

  get warnings() {
    const warnings = {};

    for (let row of this.items) {
      const warehouseInfo = this.findColorWarehouseInfo(row.colore);

      if (warehouseInfo && warehouseInfo.GiacIta < 1) {
        warnings[row.colore] = this.$t('message.Il_colore_{color}_è_esaurito,_data_di_arrivo:_{date}', {
          color: row.colore,
          date: this.formatDate(new Date(warehouseInfo.ShipDateIta))
        }).toString();
      }
    }

    return warnings;
  }

  get items() {
    let mappedRows = [];

    for (var i = 0; i < this.cartRows.length; i++) {
      let row = this.cartRows[i];
      let colore_metallo_aste;

      if (row.MetalTempleCode) {
        if (row.MetalTempleCode == "GUN") {
          colore_metallo_aste = "GUN";
        } else if (row.MetalTempleCode == "GOLD") {
          colore_metallo_aste = "GOLD";
        }
      } else {
        colore_metallo_aste = null;
      }

      let price = 0;
      let parsedModel;

      if (row.codice_modello.indexOf("FM") == -1) {
        parsedModel = _.replace(row.codice_modello, "F", "C");
      } else {
        parsedModel = row.codice_modello;
      } // let price = this.frontali.filter(f => {
      //   return f.ItemCode == row.ItemCode;
      // })[0].PRICE;


      let itemForPrice = this.frontali.filter(f => {
        return f.ItemCode == row.ItemCode;
      });

      if (itemForPrice[0]) {
        price = parseFloat(itemForPrice[0].PRICE);
      }

      if (row.nome != "") {
        //maggiorazione per laseratura nome
        price += 10;
      }

      let ls = this.$store.getters["configurator/lenti/sunLenses"].filter(l => {
        return l.ItemCode == row.lenssun;
      });
      let ls2 = this.$store.getters["configurator/lenti/clipLenses"].filter(l => {
        return l.ItemCode == row.lenssun;
      });
      let tempRow = {
        modello: row.nome_modello,
        modello_preview: "https://media.thema-optical.com/3d_models/" + parsedModel.replaceAll("CPL", "C").replaceAll("CCLIP", "C").replaceAll("SC", "C") + ".png",
        colore: row.colore_frontale,
        colore_preview: process.env.VUE_APP_COLORS_URL + row.colore_frontale + ".jpg",
        asian_fitting: row.asian_fitting == 0 ? "NO" : "SI",
        // finitura: (row.finishing == 'S') ? ($t('message.LUCIDO')) : ($t('message.OPACO')),
        finitura: row.finishing == "S" ? "LUCIDO" : row.finishing == "N" ? "NATURALE" : "OPACO",
        calibro: row.calibro,
        naso: row.naso,
        aste: row.nome_asta,
        colore_metallo_aste: colore_metallo_aste,
        colore_aste_preview: process.env.VUE_APP_COLORS_URL + row.colore_asta + ".jpg",
        nome: row.nome,
        colore_nome: row.colore_nome,
        note: row.note,
        id: row.id,
        price: price,
        lung_aste: row.lung_aste,
        lenti_sole: {
          item_code: row.lenssun,
          descr: ls.length > 0 ? ls[0].ItemName : ls2.length > 0 ? ls2[0].ItemName : ""
        },
        lenti_vista: {
          add_left: row.addleft,
          add_right: row.addright,
          axis_left: row.axisleft,
          axis_right: row.axisright,
          cyl_left: row.cylleft,
          cyl_right: row.cylright,
          height_left: row.heightleft,
          height_right: row.heightright,
          pd_left: row.pdleft,
          pd_right: row.pdright,
          sph_left: row.sphleft,
          sph_right: row.sphright,
          treatment: row.treatment,
          type: row.lensestype,
          left: row.lensleft,
          right: row.lensright,
          left_descr: row.lensleftdescr,
          right_descr: row.lensrightdescr
        }
      };
      mappedRows.push(tempRow);
    }

    return mappedRows;
  }

  get totalPrice() {
    let p = 0;

    for (let item of this.items) {
      p += +item.price;
    }

    return p.toFixed(2);
  }

  findColorWarehouseInfo(color) {
    return this.$store.state.configurator.frontali.colors.find(c => {
      return c.U_THE_COLORE == color;
    });
  }

  formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero based

    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  onCartTotalChanged(newVal, oldVal) {
    let delta; // elimino elementi da carrello ma non perchè ho fatto un checkout

    if (oldVal > newVal) {
      delta = oldVal - newVal;

      if (delta > 1) {
        this.cartUpdatedNotify = delta + " " + this.$t("message.elementi_eliminati_dal_carrello");
      } else {
        this.cartUpdatedNotify = delta + " " + this.$t("message.elemento_eliminato_dal_carrello");
      }

      if (!this.checkoutSuccess) {
        this.cartUpdated = true;
      }
    }
  }

};

__decorate([Prop()], UserCart.prototype, "cartOpen", void 0);

__decorate([Watch("cartTotal")], UserCart.prototype, "onCartTotalChanged", null);

UserCart = __decorate([Component({
  components: {
    itemCard
  },
  filters: {
    currency(c) {
      switch (c) {
        case "&dollar;":
          return "$";

        case "&pound;":
          return "£";

        default:
          return "€";
      }
    }

  }
})], UserCart);
export default UserCart;