import { __decorate } from "tslib";
import { Component, Vue, Prop } from "vue-property-decorator";
let ItemCard = class ItemCard extends Vue {
  constructor() {
    super(...arguments);
    this.lentiSoleDialog = false;
    this.lentiVistaDialog = false;
  }

  get user() {
    return this.$store.state.configurator.user.user;
  }

  get currentTab() {
    return this.$store.state.currentTab;
  }

  get isClip() {
    var _this$item$modello, _this$item$modello2;

    return ((_this$item$modello = this.item.modello) === null || _this$item$modello === void 0 ? void 0 : _this$item$modello.startsWith("CLIP")) || ((_this$item$modello2 = this.item.modello) === null || _this$item$modello2 === void 0 ? void 0 : _this$item$modello2.endsWith("CLIP"));
  }

  get activeRowShortStatus() {
    if (this.item.row_state) {
      let s = this.item.row_state;
      return s.slice(0, 2);
    }

    return "";
  }

  get activeRowIconStatus() {
    switch (this.item.row_state) {
      case "CANCELED":
        return {
          icon: "cancel",
          color: "red"
        };

      case "CONFIRMED":
        return {
          icon: "thumb_up_alt",
          color: "light-green"
        };

      case "DONE":
        return {
          icon: "check_circle",
          color: "orange"
        };

      case "NEW":
        return {
          icon: "fiber_new",
          color: "lime"
        };

      case "PRODUCING":
        return {
          icon: "construction",
          color: "blue-grey"
        };

      default:
        return {
          icon: "local_shipping",
          color: "green"
        };
    }
  }

};

__decorate([Prop()], ItemCard.prototype, "item", void 0);

__decorate([Prop({
  default: true
})], ItemCard.prototype, "canDelete", void 0);

__decorate([Prop({
  default: false
})], ItemCard.prototype, "canRestore", void 0);

__decorate([Prop({
  default: false
})], ItemCard.prototype, "showPrice", void 0);

__decorate([Prop({
  default: ""
})], ItemCard.prototype, "frontColorWarning", void 0);

ItemCard = __decorate([Component({
  filters: {
    currency(c) {
      switch (c) {
        case "&dollar;":
          return "$";

        case "&pound;":
          return "£";

        default:
          return "€";
      }
    }

  }
})], ItemCard);
export default ItemCard;