import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import _ from "lodash"; // import UserCart from './UserCart.vue'

import Profile from "./Profile.vue";
let User = class User extends Vue {
  constructor() {
    super(...arguments);
    this.userNavMini = false;
    this.loginDialog = false;
    this.userDataDialog = false;
    this.rowNotesDialog = false;
    this.username = "";
    this.cartOpen = false;
    this.rules = {
      validUsername: value => {
        const ccPattern = /\d{6,8}/g;
        const mailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let validCode = false;
        let validMail = false;

        if (mailPattern.test(value)) {
          validMail = true;
        }

        if (ccPattern.test(value)) {
          validCode = true;
        }

        if (validMail || validCode) {
          return true;
        } else {
          return "usa il codice cliente a sei cifre o una mail valida";
        }
      }
    };
    this.password = "";
    this.passwordVisible = false;
    this.loginError = false;
    this.loginMsg = "";
  }

  mounted() {
    this.getUserData();
  }

  addToCart(openCart) {
    this.rowNotesDialog = false;
    this.$store.dispatch("configurator/cart", "addToCart");

    if (openCart) {
      this.cartOpen = true;
    }
  }

  getUserData() {
    this.$store.dispatch("configurator/user/getUserData");
  }

  getUserCart() {
    this.$store.dispatch("configurator/user/getUserCart");
  }

  async login(username, password) {
    const loginRes = (await this.$store.dispatch("configurator/user/login", {
      username,
      password
    })).data;
    this.loginError = loginRes.error;
    this.loginMsg = loginRes.msg;
    console.log("LOGIN ERROR:", this.loginError);
  }

  logout() {
    this.$store.dispatch("configurator/user/logout");
  }

  saveConfiguration() {
    this.$store.commit("configurator/saveConfiguration");
  }

  get note() {
    return this.$store.state.note;
  }

  set note(value) {
    this.$store.commit("configurator/setRowNotes", value);
  }

  get isGuest() {
    return this.$store.getters["configurator/user/isGuest"];
  }

  get cartTotal() {
    return this.cartRows.length;
  }

  get profileOpen() {
    return this.$store.getters["profileOpen"];
  }

  get currentTab() {
    return this.$store.getters["currentTab"];
  }

  get configurationComplete() {
    return this.$store.getters["configurator/configurationComplete"];
  }

  get isConfigurationSaved() {
    return this.$store.getters["configurator/isConfigurationSaved"];
  }

  get canAddToCart() {
    return this.$store.getters["configurator/cart/canAddToCart"];
  }

  get user() {
    return this.$store.state.configurator.user.user;
  }

  get cartRows() {
    return this.$store.state.configurator.cart.cartRows;
  }

  onUserChange() {
    if (!_.isEmpty(this.user)) {
      this.getUserCart();
    }
  }

};

__decorate([Prop({
  default: false
})], User.prototype, "darkTheme", void 0);

__decorate([Prop()], User.prototype, "miniNav", void 0);

__decorate([Prop()], User.prototype, "haveConfigurations", void 0);

__decorate([Prop()], User.prototype, "configurations", void 0);

__decorate([Watch("user")], User.prototype, "onUserChange", null);

User = __decorate([Component({
  components: {
    Profile
  }
})], User);
export default User;