import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.replace-all.js";
import { __decorate } from "tslib";
import { Component, Vue, Watch } from "vue-property-decorator";
import itemCard from "./ItemCard.vue";
let History = class History extends Vue {
  constructor() {
    super(...arguments);
    this.search = "";
    this.headers = [{
      text: "#",
      value: "ID"
    }, {
      text: "Note",
      value: "Notes"
    }, {
      text: "Data",
      value: "date"
    }, {
      text: "Status",
      value: "status"
    }];
    this.orderDetails = [];
    this.orderStatus = [];
    this.activeOrderId = null;
    this.activeOrderState = null;
    this.detailsDialog = false;
  }

  mounted() {
    this.getUserHistory();
  }

  getUserHistory() {
    this.$store.dispatch("configurator/user/getUserHistory");
  }

  restoreConfigurationObject(m) {
    this.$store.commit("restoreConfigurationObject", m);
  }

  beforeUnmount() {
    this.orderDetails = [];
    this.orderStatus = [];
    this.activeOrderId = null;
    this.activeOrderState = null;
    this.$store.state.configurator.user.orderHistory = null;
  }

  getOrderDetails(id) {
    // @ts-ignore
    this.$rest.getOrderDetails(id).then(res => {
      this.orderDetails = res.data;
      this.activeOrderId = id;
    });
  }

  activeRowIconStatus(state) {
    switch (state) {
      case "CANCELED":
        return {
          icon: "cancel",
          color: "red",
          translation_source: "Cancellato"
        };

      case "CONFIRMED":
        return {
          icon: "thumb_up_alt",
          color: "light-green",
          translation_source: "Confermato"
        };

      case "DONE":
        return {
          icon: "check_circle",
          color: "orange",
          translation_source: "Pronto"
        };

      case "NEW":
        return {
          icon: "fiber_new",
          color: "lime",
          translation_source: "Nuovo"
        };

      case "PRODUCING":
        return {
          icon: "construction",
          color: "blue-grey",
          translation_source: "In Produzione"
        };

      case "CUTTING":
        return {
          icon: "carpenter",
          color: "blue-grey",
          translation_source: "Taglio"
        };

      case "BENDING":
        return {
          icon: "vertical_align_bottom",
          color: "blue-grey",
          translation_source: "Meniscatura"
        };

      case "TUMBLING":
        return {
          icon: "blur_on",
          color: "blue-grey",
          translation_source: "Burattatura"
        };

      case "FINISHING":
        return {
          icon: "tune",
          color: "blue-grey",
          translation_source: "Finitura"
        };

      case "START LENS CUTTING":
        return {
          icon: "lens",
          color: "blue-grey",
          translation_source: "Inizio taglio lente"
        };

      case "END LENS CUTTING":
        return {
          icon: "lens",
          color: "blue-grey",
          translation_source: "Fine taglio lente"
        };

      default:
        return {
          icon: "local_shipping",
          color: "green",
          translation_source: "Spedito"
        };
    }
  }

  cleanData() {
    this.activeOrderId = null;
    this.activeOrderState = null;
    this.orderDetails = [];
  }

  mapAndRestore() {
    let m = {
      frontale: this.orderDetails.U_THE_MODELLO,
      calibro: this.orderDetails.size_eye,
      naso: this.orderDetails.size_bridge,
      asianFitting: this.orderDetails.AsianFitting,
      colore: this.orderDetails.COLORE,
      finitura: this.orderDetails.Finishing,
      coloreAste: this.orderDetails.TemplesColorCode,
      coloreMetalloAste: null,
      misuraAste: this.orderDetails.TemplesSize,
      nome: this.orderDetails.nome,
      coloreNome: this.orderDetails.colore_nome,
      note: this.orderDetails.Note
    };
    this.restoreConfigurationObject(m);
  }

  get parsedItems() {
    let t = [];

    if (this.activeOrder) {
      this.activeOrder.details.forEach(element => {
        t.push({
          asian_fitting: element.AsianFitting == 0 || element.AsianFitting == "N" ? "NO" : "YES",
          aste: element.COD_ASTE,
          calibro: element.size_eye,
          colore: element.COLORE,
          colore_aste_preview: process.env.VUE_APP_COLORS_URL + element.TemplesColorCode + ".jpg",
          colore_metallo_aste: null,
          colore_nome: element.colore_nome,
          colore_preview: process.env.VUE_APP_COLORS_URL + (element.COLORE.startsWith("EP") ? element.COLORE : "PL0" + element.COLORE) + ".jpg",
          finitura: element.Finishing == "S" || element.Finishing == "G" ? "LUCIDO" : element.Finishing == "N" ? "NATURALE" : "OPACO",
          id: element.ID_RIGA,
          modello: element.COD_PROD,
          modello_preview: "https://media.thema-optical.com/3d_models/" + element.U_THE_MODELLO.replaceAll("CPL", "C").replaceAll("CCLIP", "C").replaceAll("F", "C").replaceAll("SC", "C") + ".png",
          naso: element.size_bridge,
          nome: element.nome,
          note: element.Note,
          row_state: element.row_state,
          lenti_sole: {
            item_code: element.SUNLENS,
            descr: element.SUNLENSDESCR
          },
          lenti_vista: {
            add_left: element.ADDLEFT,
            add_right: element.ADDRIGHT,
            axis_left: element.AXISLEFT,
            axis_right: element.AXISRIGHT,
            cyl_left: element.CYLLEFT,
            cyl_right: element.CYLRIGHT,
            height_left: element.HEIGHTLEFT,
            height_right: element.HEIGHTRIGHT,
            pd_left: element.PDLEFT,
            pd_right: element.PDRIGHT,
            sph_left: element.SPHLEFT,
            sph_right: element.SPHRIGHT,
            treatment: element.TREATMENT,
            type: element.LENSES_TYPE,
            left: element.LENSLEFT,
            right: element.LENSRIGHT,
            left_descr: element.LENSLEFTDESCR,
            right_descr: element.LENSRIGHTDESCR
          }
        });
      });
    }

    return t;
  }

  get activeOrder() {
    let self = this;

    if (this.activeOrderId) {
      return this.orderHistory.filter(e => {
        return e.ID == self.activeOrderId && (this.activeOrderState ? e.row_state == this.activeOrderState : true);
      })[0];
    }

    return null;
  }

  get user() {
    return this.$store.state.configurator.user.user;
  }

  get userIsGuest() {
    return this.$store.state.configurator.user.userIsGuest;
  }

  get orderHistory() {
    return this.$store.state.configurator.user.orderHistory || [];
  }

  onOrderHistoryChanged() {
    let ids = [];
    this.orderHistory.forEach(element => {
      ids.push(element.ID);
    });
  }

};

__decorate([Watch("orderHistory", {
  deep: true
})], History.prototype, "onOrderHistoryChanged", null);

History = __decorate([Component({
  components: {
    itemCard
  },
  filters: {
    asDate(d) {
      let date = d.split(" ")[0];
      return date.split("-").reverse().join("/");
    }

  }
})], History);
export default History;